.App {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  z-index: 0;
  overflow: hidden;
  /* font-family: "Baskerville"; */
  font-family: "Arial";
  overflow-y: auto;
  text-align: center;
}

.header {
  position: relative;
  display: block;
  width: 100%;
  height: 90px;
  line-height: 90px;
  font-size: 30px;
  margin: 108px 0px 12px 0px;
  cursor: default;
  font-family: "Baskerville";
  color: rgb(0, 0, 0);
}

.logo-container {
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  font-size: 30px;
}

.logo-container img {
  height: 100px;
  width: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.informationbox {
  position: relative;
  display: block;
  padding: 12px;
  margin: 12px 0px 12px 0px;
}

.facebook {
  font-size: 60px;
  cursor: pointer;
  color: #3b5998;
}

.facebook:hover {
  color: #6d84b4;
}
